<template>
    <footer>
        <div class="footer-container">
            <div class="footer-logo">
                <img src="https://storage.googleapis.com/cdn-seoul/henergy-web/h_logo.svg" alt="">
            </div>
            <div class="address mobile-text-sm">
                <address>
                    <dl>
                        <dt @click="open_modal('info')">プライバシーポリシー</dt>
                    </dl>
                    <dl>
                        <dt @click="open_modal('site')">このサイトについて</dt>
                    </dl>
                    <dl>
                        <dt @click="open_modal('company')">会社概要</dt>
                    </dl>
                </address>
            </div>
            <div class="copyright">
                <p>Copyright ©2023 All rights reserved.</p>
                <p>電話番号および電子メールの無断収集を禁止します。</p>
            </div>
        </div>
        <div class="footer-modal" v-if="show_modal">
            <div class="footer-modal-container">
                <div class="footer-modal-wrap">
                    <div class="footer-modal-header">
                        <p>{{ modal_title }}</p>
                        <div class="close_btn">
                            <img src="@/assets/img/close.svg" alt="닫기버튼" @click="show_modal = false" />
                        </div>
                    </div>
                    <div class="footer-modal-body">
                        <FooterInfo v-if="modal_type === 'info'"></FooterInfo>
                        <FooterSite v-if="modal_type === 'site'"></FooterSite>
                        <div v-if="modal_type === 'company'" class="footer-company">
                            <div class="line-box">
                                <p class="line-1">【会社名】</p>
                                <p class="line-2">H-energy</p>
                            </div>
                            <div class="line-box">
                                <p class="line-1">【資本金】</p>
                                <p class="line-2">1.6億ウォン（約1,718万円）</p>
                            </div>
                            <div class="line-box">
                                <p class="line-1">【売上金】</p>
                                <p class="line-2">223.8億ウォン（約24億円）</p>
                            </div>
                            <div class="line-box">
                                <p class="line-1">【会社員数】</p>
                                <p class="line-2">71名</p>
                            </div>
                            <div class="line-box">
                                <p class="line-1">【ホームページ】</p>
                                <p class="line-2">https://www.henergy.xyz/</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-modal-dim" @click="show_modal = false"></div>
        </div>
    </footer>
</template>
<script>
import { ref } from "@vue/reactivity";
import FooterInfo from '../Elements/FooterInfo.vue'
import FooterSite from '../Elements/FooterSite.vue'

export default {
    components: {
        FooterInfo,
        FooterSite,
    },
    setup() {
        let device_width = ref(window.innerWidth);
        window.addEventListener('resize', () => {
            device_width.value = window.innerWidth;
        })

        let show_modal = ref(false);
        let modal_type = ref('');
        let modal_title = ref('');

        const open_modal = (type) => {
            show_modal.value = true;
            modal_type.value = type;

            if (type === 'info') {
                modal_title.value = 'プライバシーポリシー'
            } else if (type === 'site') {
                modal_title.value = 'このサイトについて'
            } else {
                modal_title.value = '会社概要'
            }
        }

        return {
            open_modal,
            show_modal,
            modal_type,
            modal_title,
        };
    },
};
</script>
<style lang="scss" scoped>
* {
    font-family: "Pretendard JP";
}

.footer-container {
    padding-left: 0;
    padding-right: 0;
}

.address dt {
    cursor: pointer;
    font-weight: 600;
    margin-right: 0;
}

.copyright {
    padding: 50px 0 80px;

    p {
        color: #CCCCCC;
        font-size: 16px;
    }
}

.footer-modal-header {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    z-index: 99;
    padding: 20px 23px 30px;
    text-align: center;
}

.footer-modal {
    z-index: 999;
    position: fixed;
}

.footer-modal-container {
    width: 610px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 16px;
}

.footer-modal-wrap {
    display: flex;
    flex-direction: column;
}

.footer-modal-body {
    padding: 0 20px 20px 20px;
}

.footer-company {
    .line-box {
        padding-bottom: 20px;
    }

    .line-1 {
        font-size: 20px;
        font-weight: 500;
        margin-left: -12px;
    }

    .line-2 {
        font-size: 18px;
        font-weight: 500;
    }
}

.footer-modal-dim {
    background-color: #0000009e;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 98;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, Opera*/
        background: transparent;
        /* Optional: just make scrollbar invisible */
        -webkit-appearance: none;
    }
}

@media screen and (max-width: 1025px) {
    .mobile-text-sm>address dt {
        font-size: 12px;
    }

    .footer-modal-container {
        width: 90%;
        max-width: 610px;
    }
}
</style>
