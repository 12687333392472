<template>
  <section class="section service-section">
    <div class="inner-section inner-section-grid">
      <div class="inner-section-title">
        <Heading title="H Energy">
          <template v-slot:multi-title>
            <p class="after no-padding">プラットフォーム技術</p>
          </template>
        </Heading>
        <div class="inner-service-logo">
          <div class="service-logo">
            <img src="@/assets/img/img-solution-logo_der.png" alt="">
          </div>
        </div>
      </div>
      <div class="inner-section-grid-second-div inner-section-content">
        <div class="solution" v-for="der in der_solutions" :key="der">
          <Solution :title="der.title" :eng="der.eng" :subtitle="der.subtitle" :content_title="der.content_title"
            :content_01="der.content_01" :content_02="der.content_02" :content_03="der.content_03"
            :content_04="der.content_04" :content_05="der.content_05" />
        </div>
      </div>
      <div class="img-box">
        <img src="https://storage.googleapis.com/cdn-seoul/henergy-web/der_solution_arrow.png" alt="" />
      </div>
      <div class="solution arrow">
        <div class="solution-container">
          <div class="solution-title">
            <h3 class="solution-subtitle jp">停滞せずに継続的に成長していく人工知能</h3>
          </div>
          <div class="solution-content">
            <p class="content-title">最大発電効率のための機材の変更を提案<br>機材変更後の収益を予測</p>
            <p>
              ピーク時の発電量を確認し、発電所の最大発電効率を設定、その後の発電量の変化を追跡して発電所の劣化を分析します。<br>発電所の発電効率が一定レベル以下に低下したとき、パワコンなどの設備交換費に対する収益予測をレポートし、発電所の稼働期間中における最大収益を示します。
            </p>
            <p>日射量パターンと発電量パターンの類似度を分析することにより<br>発電所の最大予測発電量と、そのために求められる設備の設計などのデータを提供します。</p>
            <p style="margin-bottom: 0">
              環境条件に加えて、発電収益に影響を与えるモジュールの性能を分析します。<br>モジュールの劣化を追跡し、発電所施工、機材変更における収益分析のためのデータとして活用されます。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="inner-section img-section">
      <img v-if="device_width > 1024" src="https://storage.googleapis.com/cdn-seoul/henergy-web/der_solution_img.png"
        alt="">
      <img v-else src="https://storage.googleapis.com/cdn-seoul/henergy-web/m_der_solution_img.png" alt="">
    </div>
  </section>
</template>

<script>
import Heading from "../../Elements/HeadingTitle.vue";
import Solution from "../Elements/Solution.vue";
import { onMounted, ref, reactive } from "vue";

export default {
  components: {
    Heading,
    Solution,
  },
  name: "dershare-solutions",
  setup() {
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        device_width.value = window.innerWidth;
      })
    })
    const der_solutions = reactive([
      {
        title: "DER Share Solutions",
        subtitle: `蓄電池（ESS）やEV充電器、DR（節電管理）など、分散型エネルギー源（DER）をVPPとして統合運用し、AIの活用を通じて需要ピークの回避や市場価格の予測などに基づいて、収益を最大化するサービス`,
        content_title: `再エネのデジタルプラットフォーム`,
        content_01: `プラットフォーム戦略を実現するための通信技術と自動化、そしてデータサイエンス技術を活用、Ｏ2Ｏの統合等をつうじて取引コストを最小化`,
        content_02: `サービスのカスタマイズを通じて、プラットフォームエコシステム内の取引を促進`,
        content_03: `発電所の遠隔監視`,
        content_04: `数多くの小規模分散型発電所をＡＩで自動管理`,
        content_05: `データ収集、データ分析、異常検知を自動で行った上で、専門技術者による検証を実施、管理者の安全点検をサポ`,
      },
      {
        title: "H EnergyのVPPシミュレーション",
        content_01: `電力市場における予測を、多変数確率予測法によって実施、最適入札戦略を実行`,
        content_02: `Clustering（データ間の類似度に基づいてデータを分類する手法）技術によるモデリングによる、パターン解析アルゴリズムを通じて、予測精度の最大化を追求、多様な分散電源の運用に合わせた戦略、および目的に合わせた戦略の確立が可能`,
        content_03: `リアルタイムデータの収集と分析分析による誤差の補正`,
        content_04: `最適アルゴリズムの探索を自動化、予測調整と運用最適化を実現、VPP運用スケジュールを最適化`,
        content_05: `高度なVPPモニタリング制御により、誤作動を自動検出、最高のパフォーマンスとデバイスの劣化を防止`,
      },
    ]);
    return {
      device_width,
      der_solutions,
    };
  },
};
</script>
<style lang="scss" scoped>
.solution {
  &:nth-child(2) {
    padding: 120px 0 !important;
  }
}

.solution.arrow {
  padding: 0 70px !important;
}

.solution-content {
  padding-top: 30px !important;

  .content-title {
    font-weight: 600 !important;
    line-height: 40px !important;
    margin-bottom: 0 !important;
  }

  p {
    line-height: 30px !important;
    word-break: normal !important;
    margin-bottom: 0 !important;
  }

  p:not(:last-child) {
    padding-bottom: 30px;
  }
}

.img-section {
  text-align: right;

  img {
    padding-right: 50px;
    width: 70%;
  }
}

.img-box {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 400px;
  }
}

@media screen and (max-width: 1025px) {
  .solution {
    &:nth-child(2) {
      padding: 30px 0 !important;
    }
  }

  .solution.arrow {
    padding: 0 !important;
  }

  .solution-content {
    .content-title {
      font-size: 16px !important;
      font-weight: 700 !important;
      line-height: 21px !important
    }

    p {
      padding-bottom: 10px !important;
      line-height: 19px !important;
    }

    p:not(:last-child) {
      padding-bottom: 10px;
    }
  }

  .img-section {
    text-align: center;

    img {
      padding-right: 0;
      width: 100%;
    }
  }

  .img-box img {
    width: 250px;
    padding-bottom: 30px;
  }
}
</style>